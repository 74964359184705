<template>
  <Layout>
    <template
      v-if="row.label"
      v-slot:label
    >
      {{ row.label }}
    </template>

    <template
      v-if="row.extra"
      v-slot:extra
    >
      {{ row.extra }}
    </template>

    <template v-slot:input>
      <div class="block">
        <div
          v-if="hasMultipleOptions"
          class="flex -mx-1"
        >
          <template v-for="(label, key) in row.options">
            <!-- <template v-if="isSimple">
                  <a @click.prevent="action" href="#" class="block mb-2 text-sm leading-tight text-blue-500 hover:underline hover:text-blue-400">{{ label }}</a>
              </template>
              <template v-else> -->
            <div
              class="btn btn-xs mb-2 mx-1"
              :class="btnClass"
              @click.prevent="action"
            >
              {{ label }}
            </div>
            <!-- </template> -->
          </template>
        </div>
        <template v-else>
          <template v-if="isSimple">
            <a
              href="#"
              class="text-xs leading-tight text-blue-500 hover:underline hover:text-blue-400"
              :class="btnClass"
              @click.prevent="action"
            >{{ row.value }}</a>
          </template>
          <template v-else>
            <button
              type="button"
              class="btn btn-xs btn-fluid"
              :class="btnClass"
              @click.prevent="action"
            >
              {{ row.value }}
            </button>
          </template>
        </template>
      </div>
    </template>
  </Layout>
</template>
<script>
import inputs from './InputMixin'

export default {
  name: 'ButtonInput',

  mixins: [inputs],

  computed: {

    btnClass () {
      let base = ''
      if (this.row.inputClass) {
        base = this.row.inputClass
      }

      if (this.isInverted) {
        base = base + ' btn-dark-inverted'
      } else {
        base = base + ' btn-dark'
      }

      if (this.isDisabled) {
        base = base + ' disabled opacity-50'
      }

      return base
    },

    hasMultipleOptions () {
      return this.row.options !== undefined
    }
  },

  methods: {
    action (key) {
      this.$emit('change', key)
    }
  }
}

</script>
